import { render, staticRenderFns } from "./LinkedinModal.vue?vue&type=template&id=629ffd1f&"
import script from "./LinkedinModal.vue?vue&type=script&lang=ts&"
export * from "./LinkedinModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports