
















import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import { debounce } from 'lodash-es';

let counter = 0;

@Component
class LocationInput extends Vue {
  @Prop() city: string;
  @Prop() country: string;
  @Prop({ default: null }) state: boolean | null;

  locationSelected = false;
  location = '';
  locations = {
    array: [],
  };

  randomId() {
    counter += 1;
    return `${new Date().getTime()}${counter}`;
  }

  mounted() {
    if (this.city && this.country) {
      this.location = `${this.city}, ${this.country}`;
    }
  }

  locationHitHandler(item: { city: string; country: string; name: string }) {
    if (item != null) {
      this.location = `${item.city}, ${item.country}`;
      this.$emit('updateLocation', item.city, item.country);
      this.locationSelected = true;
    }
  }

  onBlur() {
    if (!this.locationSelected) {
      let existsInSuggestions = false;
      this.locations.array.forEach((suggestedLocation: any) => {
        if (suggestedLocation.name === this.location) {
          existsInSuggestions = true;
        }
      });
      if (!existsInSuggestions) {
        this.location = '';
        this.$emit('updateLocation', '', '');
      }
    }
  }

  @Watch('city')
  @Watch('country')
  updateLocation() {
    if (this.city && this.country) {
      this.location = `${this.city}, ${this.country}`;
    }
  }

  @Watch('location')
  locationSetter(location: string) {
    if (!location || location.length < 2) {
      this.$set(this.locations, 'array', []);
      this.$emit('updateLocation', '', '');
      return;
    }
    if (location) this.sendLocationRequest(location);
    this.$emit('set', 'location', location);
  }

  sendLocationRequest = debounce(async function sendLocationRequest(change: string) {
    // @ts-ignore
    const response = await this.$store.dispatch('getLocationSuggestion', change);
    if (response.suggestions != null) {
      const resData: any = [];
      response.suggestions.forEach((suggestion: any) => {
        if (suggestion != null) {
          resData.push({
            city: suggestion.city,
            country: suggestion.country,
            name: `${suggestion.city}, ${suggestion.country}`,
          });
        }
      });
      if (resData.length > 0) {
        // @ts-ignore
        this.$set(this.locations, 'array', resData);
      }
    }
  }, 500);
}
export default LocationInput;
