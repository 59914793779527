







































































































































































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { debounce } from 'lodash-es';
import axios from 'axios';
import { format } from 'date-fns';
import SkillsExperienceSelect from '../../shared/SkillsExperienceSelect.vue';

@Component({
  components: {
    SkillsExperienceSelect,
  },
})
class PortfolioForm extends Vue {
  @Prop() portfolio: any;

  dragOver = false;
  companies = {
    array: [] as any[],
  };

  get picture(): string {
    return this.portfolio.picture || '';
  }

  set picture(picture: string) {
    this.$emit('set', 'picture', picture);
  }

  get picturePreview(): string {
    return this.portfolio.picturePreview || '';
  }

  set picturePreview(picturePreview: string) {
    this.$emit('set', 'picturePreview', picturePreview);
  }

  get title(): string {
    return this.portfolio.title || '';
  }

  set title(title) {
    this.$emit('set', 'title', title);
  }

  get role(): string {
    return this.portfolio.role || '';
  }

  set role(role) {
    this.$emit('set', 'role', role);
  }

  get highlightedSkills(): string[] {
    return [...(this.portfolio.highlightedSkills || [])];
  }

  set highlightedSkills(highlightedSkills) {
    this.$emit('set', 'highlightedSkills', [...highlightedSkills]);
  }

  get skills(): string[] {
    return [...(this.portfolio.skills || [])];
  }

  set skills(skills) {
    this.$emit('set', 'skills', [...skills]);
  }

  get currentlyWorkingHere(): boolean {
    return this.portfolio.currentlyWorkingHere;
  }

  set currentlyWorkingHere(currentlyWorkingHere) {
    this.$emit('set', 'currentlyWorkingHere', currentlyWorkingHere);
  }

  get description(): string {
    return this.portfolio.description || '';
  }

  set description(description) {
    this.$emit('set', 'description', description);
  }

  get demoUrl(): string {
    return this.portfolio.demoUrl || '';
  }

  set demoUrl(demoUrl) {
    this.$emit('set', 'demoUrl', demoUrl);
  }

  get sourceUrl(): string {
    return this.portfolio.sourceUrl || '';
  }

  set sourceUrl(sourceUrl) {
    this.$emit('set', 'sourceUrl', sourceUrl);
  }

  get startDateMonth(): number {
    return this.portfolio.startDateMonth;
  }

  set startDateMonth(startDateMonth) {
    this.$emit('set', 'startDateMonth', startDateMonth);
  }

  get startDateYear(): number {
    return this.portfolio.startDateYear;
  }

  set startDateYear(startDateYear) {
    this.$emit('set', 'startDateYear', startDateYear);
  }

  get endDateMonth(): number {
    return this.portfolio.endDateMonth;
  }

  set endDateMonth(endDateMonth) {
    this.$emit('set', 'endDateMonth', endDateMonth);
  }

  get endDateYear(): number {
    return this.portfolio.endDateYear;
  }

  set endDateYear(endDateYear) {
    this.$emit('set', 'endDateYear', endDateYear);
  }

  get areDatesValid() {
    if (this.startDateMonth && this.startDateYear && this.endDateMonth && this.endDateYear) {
      return (
        new Date(`${this.startDateYear}-${this.startDateMonth.toString().padStart(2, '0')}`).getTime() <=
        new Date(`${this.endDateYear}-${this.endDateMonth.toString().padStart(2, '0')}`).getTime()
      );
    }
    return true;
  }

  @Watch('areDatesValid')
  onDatesValid(valid: any) {
    this.$emit('datesValid', valid);
  }

  get companyName() {
    return this.portfolio.companyName || '';
  }

  set companyName(name) {
    this.$emit('set', 'companyName', name);
  }

  @Watch('companyName')
  companySetter(companyName: string) {
    if (companyName) this.sendCompanyRequest(companyName);
  }

  companyHitHandler(item: { domain: string; logo: string; name: string }) {
    if (item.name.includes('(') && item.name.includes(')')) {
      this.companyName = item.name.replace(/ *\([^)]*\) */g, '');
    } else {
      this.companyName = item.name;
    }
  }

  sendCompanyRequest = debounce(async function sendCompanyRequest(this: PortfolioForm, change: string) {
    // First check our db
    const response = await this.$store.dispatch('getCompanySuggestion', change);
    let foundInDB = false;
    if (response.companies != null) {
      const resData: any = [];
      response.companies.forEach((company: any) => {
        resData.push({
          domain: '',
          logo: company.logo,
          name: `${company.name} (${company.count})`,
        });
      });
      if (resData.length > 0) {
        foundInDB = true;
        this.$set(this.companies, 'array', resData);
      }
    }
    // If there is no suggestions from our db, check clearbit
    if (!foundInDB) {
      try {
        const res = await axios.get('https://autocomplete.clearbit.com/v1/companies/suggest', {
          params: {
            query: change,
          },
        });
        this.$set(this.companies, 'array', res.data);
      } catch (err) {
        // clearbit error
      }
    }
  }, 500);

  onFileChange(e: any) {
    if (e.type === 'drop') {
      e.preventDefault();
      this.dragOver = false;
    }
    const file = e.type === 'drop' ? e.dataTransfer.files[0] : e.target.files[0];
    if (!file) return;
    const reader = new FileReader();
    reader.onload = () => {
      this.$emit('set', 'picture', file);
      this.$emit('set', 'picturePreview', reader.result);
    };
    reader.readAsDataURL(file);
  }

  get getMaxPossibleStartMonth() {
    return this.startDateYear === new Date().getFullYear() ? new Date().getMonth() + 1 : 12;
  }

  get getMaxPossibleEndMonth() {
    return this.endDateYear === new Date().getFullYear() ? new Date().getMonth() + 1 : 12;
  }

  getCurrentYear() {
    return new Date().getFullYear();
  }

  monthToString(month: number): string {
    return format(new Date(0, month, 0), 'MMMM');
  }

  rangeAsc(a: number, b: number): number[] {
    const numbers = [] as number[];
    for (let i = a; i <= b; i += 1) {
      numbers.push(i);
    }
    return numbers;
  }

  rangeDesc(a: number, b: number): number[] {
    const numbers = [] as number[];
    for (let i = a; i > b; i -= 1) {
      numbers.push(i);
    }
    return numbers;
  }

  fetchSkills(query: string, resolve: any) {
    this.$store.dispatch('getSkillSuggestion', query).then((skills: string[]) => {
      resolve(skills);
    });
  }
}
export default PortfolioForm;
