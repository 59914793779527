





























import Vue from 'vue';
import Component from 'vue-class-component';
import { education } from '../../../store/education';
import { getUTCDate } from '../../../utils/date';
import EducationForm from './EducationForm.vue';

@Component({
  components: {
    EducationForm,
  },
})
class AddEditEducationModal extends Vue {
  formChanged = false;
  endDateIsLower = false;
  id = -1;

  education: any = {
    school: '',
    degree: '',
    field: '',
    startMonth: null,
    startYear: null,
    endMonth: null,
    endYear: null,
    currentlyStudyingHere: false,
    grade: '',
    description: '',
  };

  mounted() {
    const edu = this.$store.getters[education.getters.SelectedEducation];
    this.id = edu.id;
    this.education = {
      school: edu.school || '',
      degree: edu.degree || '',
      field: edu.field || '',
      startMonth: edu.dateFrom ? getUTCDate(edu.dateFrom).getMonth() + 1 : null,
      startYear: edu.dateFrom ? getUTCDate(edu.dateFrom).getFullYear() : null,
      endMonth: edu.dateFrom ? getUTCDate(edu.dateTo).getMonth() + 1 : null,
      endYear: edu.dateFrom ? getUTCDate(edu.dateTo).getFullYear() : null,
      currentlyStudyingHere: edu.currentlyStudyingHere || false,
      grade: edu.grade || null,
      description: edu.description || '',
    };
  }

  updateEducation(field: string, value: any) {
    const { school } = this.education;
    if ((field === 'school' && value !== school) || field !== 'school') {
      this.formChanged = true;
    }
    this.education[field] = value;
  }

  saveHandler() {
    // @ts-ignore
    this.$refs.form.validate().then((valid) => {
      if (!valid || this.endDateIsLower) return;

      const edu = {
        id: this.id,
        ...this.education,
      };

      if (this.$store.state.education.modalEducation === 'add') {
        this.$store.dispatch(education.actions.CreateEducation, edu);
        this.$store.dispatch('tryToGetAccount');
      } else {
        this.$store.dispatch(education.actions.EditEducation, edu);
      }
    });
  }

  deleteHandler() {
    this.$store.dispatch(education.actions.DeleteEducation, this.id);
  }
}
export default AddEditEducationModal;
