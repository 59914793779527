


















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import LibraryIcon from '../../shared/LibraryIcon.vue';

interface Library {
  name: string;
  country?: number;
  countryAll?: number;
  score: number;
  topXPercent: number;
  worldWideAll: number;
  technologies: string[];
}

@Component({
  components: {
    LibraryIcon,
  },
  props: {
    showScore: {
      type: Boolean,
      default: true,
    },
  },
})
class TechnologyCard extends Vue {
  @Prop() library: Library;
  tooltipContent(score: number, worldWideAll: number): string {
    return `<span style="opacity: 0.8">Out of ${worldWideAll} with ${score} score</span>`;
  }

  format(percentage: number) {
    if (percentage) {
      return parseFloat(percentage.toFixed(2).replace(/\.0+$/, ''));
    }
    return '';
  }
}

export default TechnologyCard;
