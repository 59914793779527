






































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import ProfileBlock from './ProfileBlock.vue';
import languagesList from '../../utils/languages-list';
import { responseError } from '../../utils/response-error';

@Component({
  components: {
    ProfileBlock,
  },
})
class AboutEditModal extends Vue {
  @Prop({ type: Boolean }) inline: boolean;

  formChanged = false;
  saving = false;
  errorMessage = '';
  aboutEdit: string = '';
  languagesEdit = [] as { code: string; proficiency: string }[];
  languageCodesList = languagesList.map((l) => l.code);
  proficiencyList: any = {
    elementary: 'Elementary proficiency',
    limited: 'Limited working proficiency',
    professional: 'Professional proficiency',
    full: 'Full professional proficiency',
    native: 'Native or bilingual proficiency',
  };

  get about() {
    return this.$store.state.profile.ownProfile ? this.$store.state.account.about : this.$store.state.profile.about;
  }

  get languages() {
    const languages = this.$store.state.profile.ownProfile
      ? this.$store.getters.account.languages
      : this.$store.getters.profile.languages;
    return languages || [];
  }

  languageName(code: string) {
    const language: any = languagesList.filter((l) => l.code === code)[0];
    return `${language.name} (${language.nativeName})`;
  }

  created() {
    this.aboutEdit = this.about || '';
    this.languagesEdit = [...(this.languages || [])].map((language: any) => ({ ...language }));
  }

  addLanguage() {
    const defaultLanguage = (navigator.language || 'en').split('-')[0];
    this.languagesEdit.push({ code: defaultLanguage, proficiency: 'elementary' });
    this.formChanged = true;
  }

  removeLanguage(index: number) {
    this.languagesEdit.splice(index, 1);
  }

  closeModal() {
    this.$emit('close');
  }

  save() {
    const { aboutEdit, languagesEdit } = this;

    const filteredLanguages: any[] = [];
    languagesEdit.forEach((language) => {
      if (filteredLanguages.filter((l) => l.code === language.code).length) return;
      filteredLanguages.push(language);
    });
    // @ts-ignore
    return this.$refs.form.validate().then((valid) => {
      if (!valid) return Promise.reject();
      this.saving = true;
      return this.$store
        .dispatch('updateAbout', {
          about: aboutEdit,
          languages: filteredLanguages,
        })
        .then(() => {
          this.saving = false;
          this.$store.dispatch('tryToGetAccount');
          this.closeModal();
        })
        .catch((err: any) => {
          this.saving = false;
          this.errorMessage = responseError(err);
        });
    });
  }
}

export default AboutEditModal;
