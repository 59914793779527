

























import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { format } from 'date-fns';
import { Certificate, education } from '../../../store/education';
import { getUTCDate } from '../../../utils/date';
import DataCard from '../../shared/DataCard.vue';

@Component({
  components: {
    DataCard,
  },
})
class CertificateCard extends Vue {
  @Prop() certificate: Certificate;

  get isAsOthersSee() {
    return this.$route.name && this.$route.name.indexOf('AsOthersSee') > 0;
  }

  openEditModal() {
    this.$store.dispatch(education.actions.OpenEditCertificateModal, this.certificate.id);
  }

  formatDate(date: string) {
    if (!date) return '';
    return format(getUTCDate(date), 'MMM yyyy');
  }

  formatURL(url: string) {
    if (!/^https?:\/\//i.test(url)) {
      return `http://${url}`;
    }
    return url;
  }

  editHandler() {
    this.$store.dispatch(education.actions.OpenEditCertificateModal, this.certificate.id);
  }
}
export default CertificateCard;
