





























import Vue from 'vue';
import Component from 'vue-class-component';
import WorkExperienceForm from './WorkExperienceForm.vue';
import { workexperience } from '../../../store/workexperience';

@Component({
  components: { WorkExperienceForm },
})
class AddEditModal extends Vue {
  /*
      Notes:
      - companyName is not reactive if implemented using getters/setters,
      - companies is an object because if you make the array a property of the AddEditModal class it will not be reactive

      If you know how to fix these feel free to do so
   */

  id = -1;
  formChanged = false;

  saveHandler() {
    // @ts-ignore
    this.$refs.form.validate().then((isValid) => {
      if (!isValid) return;
      if (this.id < 0 || typeof this.id === 'undefined') {
        this.$store.dispatch(workexperience.actions.CreateWorkExperience);
        this.$store.dispatch('tryToGetAccount');
      } else {
        this.$store.dispatch(workexperience.actions.EditWorkExperience);
      }
    });
  }

  deleteHandler() {
    this.$store.dispatch(workexperience.actions.DeleteWorkExperience);
  }

  mounted() {
    this.id = this.$store.state.workexperience.modal.selectedID;
  }

  updateExperience(field: string, value: any) {
    const { companyName, location } = this.$store.state.workexperience.modal;
    if (
      (field === 'companyName' && value !== companyName) ||
      (field === 'location' && value !== location) ||
      (field !== 'companyName' && field !== 'location')
    ) {
      this.formChanged = true;
    }

    this.$store.commit(workexperience.mutations.ModifyModalForm, { field, value });
  }
}
export default AddEditModal;
