























































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import Logo from '../shared/Logo.vue';
import { responseError } from '../../utils/response-error';

@Component({
  components: {
    Logo,
  },
})
class LinkedInModal extends Vue {
  step: string = 'intro';
  linkedIn = '';
  errorMessage = '';
  loading = false;
  saving = false;

  created() {
    this.linkedIn = this.$store.state.account.linkedIn || '';
  }

  setStep(step: string) {
    this.step = step;
  }

  async fetchData() {
    // @ts-ignore
    const valid = await this.$refs.form.validate();
    // eslint-disable-next-line
    if (!valid || this.linkedIn.indexOf('javascript:') >= 0) return;
    this.loading = true;
    try {
      await this.$store.dispatch('updateProfileLinkedIn', { linkedIn: this.linkedIn });
      const res = await this.$store.dispatch('getLinkedInExperience');
      const { about = '' } = res.data || {};
      this.loading = false;
      if (!about.trim().length) {
        this.setStep('empty');
      } else {
        await this.saveIntro(about);
        this.setStep('success');
      }
    } catch (err) {
      this.errorMessage = responseError(err);
      this.loading = false;
    }
  }

  async saveIntro(about: string = '') {
    this.saving = true;

    const languages = this.$store.state.profile.ownProfile
      ? this.$store.getters.account.languages
      : this.$store.getters.profile.languages;

    await this.$store.dispatch('updateAbout', {
      about,
      languages,
    });
    await this.$store.dispatch('tryToGetAccount');
  }
}
export default LinkedInModal;
