

























































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import Cookies from 'js-cookie';
import { Watch, Prop } from 'vue-property-decorator';
import { debounce } from 'lodash-es';
import axios from 'axios';
import { format } from 'date-fns';
import LocationInput from '../../shared/LocationInput.vue';
import SkillsExperienceSelect from '../../shared/SkillsExperienceSelect.vue';

@Component({
  components: {
    LocationInput,
    SkillsExperienceSelect,
  },
})
class WorkExperienceForm extends Vue {
  @Prop() mergeTechnologies: boolean;
  @Prop() workExperience: any;
  @Prop() linkedin: boolean;
  @Prop() allowCustomLocation: boolean;
  /*
      Notes:
      - companyName is not reactive if implemented using getters/setters,
      - companies is an object because if you make the array a property of the AddEditModal class it will not be reactive

      If you know how to fix these feel free to do so
   */

  companyName = '';
  companies = {
    array: [] as any[],
  };

  location = '';
  city = '';
  country = '';

  get areDatesValid() {
    if (this.currentlyWorkingHere) return true;
    if (!(this.startDateMonth && this.startDateYear && this.endDateMonth && this.endDateYear)) return true;
    const isValid =
      new Date(`${this.startDateYear}-${this.startDateMonth.toString().padStart(2, '0')}`).getTime() <=
      new Date(`${this.endDateYear}-${this.endDateMonth.toString().padStart(2, '0')}`).getTime();
    return isValid;
  }

  get getMaxPossibleStartMonth() {
    return this.startDateYear === new Date().getFullYear() ? new Date().getMonth() + 1 : 12;
  }

  get getMaxPossibleEndMonth() {
    return this.endDateYear === new Date().getFullYear() ? new Date().getMonth() + 1 : 12;
  }

  getCurrentYear() {
    return new Date().getFullYear();
  }

  async fileUploadHandler(event: any) {
    const file = event.target.files[0];
    if (!file) return;
    const formData = new FormData();
    formData.append('picture', file);
    formData.set('sessionID', Cookies.get('sessionId'));
    formData.set('filename', file.name);

    try {
      const response = await axios.post(
        `${(axios.defaults as any).gatewayBase}/candidate/candidate/UploadCompanyPicture`,
        formData,
      );
      this.companyLogo = response.data.url;
    } catch (err) {
      this.$sentry.captureException(err);
    }
  }

  updateCustomLocation(value: string) {
    this.$emit('set', 'location', value);
  }

  updateLocation(city: string, country: string) {
    if (city && country) {
      this.location = `${city}, ${country}`;
      this.$emit('set', 'location', `${city}, ${country}`);
    } else {
      this.location = '';
      this.$emit('set', 'location', '');
    }
  }

  triggerFileUpload() {
    if (!this.$refs.companyLogoUpload) return;
    (this.$refs.companyLogoUpload as any).click();
  }

  companyHitHandler(item: { domain: string; logo: string; name: string }) {
    if (item.name.includes('(') && item.name.includes(')')) {
      this.companyName = item.name.replace(/ *\([^)]*\) */g, '');
    } else {
      this.companyName = item.name;
    }
    this.companyLogo = item.logo;
  }

  monthToString(month: number): string {
    return format(new Date(0, month, 0), 'MMMM');
  }

  mounted() {
    this.companyName = this.workExperience.companyName || '';
    if (this.linkedin && this.companyName) {
      this.$nextTick(() => {
        this.sendCompanyRequest(this.companyName);
      });
    }
    this.location = this.workExperience.location || '';
    if (this.location) {
      const locations = this.location.split(',').map((i) => i.trim());
      if (locations.length > 1) {
        this.city = locations[0];
        this.country = locations[1];
      }
    }
  }

  get title(): string {
    return this.workExperience.title;
  }

  set title(title) {
    this.$emit('set', 'title', title);
  }

  get technologies(): string[] {
    return [...(this.workExperience.technologies || [])];
  }

  set technologies(technologies) {
    this.$emit('set', 'technologies', [...(technologies || [])]);
  }

  get highlightedTechnologies(): string[] {
    return [...(this.workExperience.highlightedTechnologies || [])];
  }

  set highlightedTechnologies(highlightedTechnologies) {
    this.$emit('set', 'highlightedTechnologies', [...(highlightedTechnologies || [])]);
  }

  get otherTechnologies(): string[] {
    return [...(this.workExperience.otherTechnologies || [])];
  }

  set otherTechnologies(otherTechnologies) {
    this.$emit('set', 'otherTechnologies', [...(otherTechnologies || [])]);
  }

  get companyLogo(): string {
    return this.workExperience.companyLogo;
  }

  set companyLogo(companyLogo) {
    this.$emit('set', 'companyLogo', companyLogo);
  }

  get isRemote() {
    return this.location === 'Remote, Earth';
  }

  set isRemote(val) {
    if (val === true) {
      this.location = 'Remote, Earth';
      this.$emit('set', 'location', this.location);
    } else {
      this.location = '';
    }
  }

  fetchSkills(query: string, resolve: any) {
    this.$store.dispatch('getSkillSuggestion', query).then((skills: string[]) => {
      resolve(skills);
    });
  }

  @Watch('companyName')
  companySetter(companyName: string) {
    if (companyName) this.sendCompanyRequest(companyName);
    this.$emit('set', 'companyName', companyName);
  }

  get startDateMonth(): number {
    return this.workExperience.startDateMonth;
  }

  set startDateMonth(startDateMonth) {
    this.$emit('set', 'startDateMonth', startDateMonth);
  }

  get startDateYear(): number {
    return this.workExperience.startDateYear;
  }

  set startDateYear(startDateYear) {
    this.$emit('set', 'startDateYear', startDateYear);
  }

  get endDateMonth(): number {
    return this.workExperience.endDateMonth;
  }

  set endDateMonth(endDateMonth) {
    this.$emit('set', 'endDateMonth', endDateMonth);
  }

  get endDateYear(): number {
    return this.workExperience.endDateYear;
  }

  set endDateYear(endDateYear) {
    this.$emit('set', 'endDateYear', endDateYear);
  }

  get currentlyWorkingHere(): boolean {
    return this.workExperience.currentlyWorkingHere;
  }

  set currentlyWorkingHere(currentlyWorkingHere) {
    this.$emit('set', 'currentlyWorkingHere', currentlyWorkingHere);
  }

  get description(): string {
    return this.workExperience.description;
  }

  set description(description) {
    this.$emit('set', 'description', description);
  }

  sendCompanyRequest = debounce(async function sendCompanyRequest(this: WorkExperienceForm, change: string) {
    // First check our db
    const response = await this.$store.dispatch('getCompanySuggestion', change);
    let foundInDB = false;
    if (response.companies != null) {
      const resData: any = [];
      response.companies.forEach((company: any) => {
        resData.push({
          domain: '',
          logo: company.logo,
          name: `${company.name} (${company.count})`,
        });
      });
      if (resData.length > 0) {
        foundInDB = true;
        this.$set(this.companies, 'array', resData);
        if (this.linkedin && this.companies.array[0] && this.companies.array[0].logo) {
          this.$emit('set', 'companyLogo', this.companies.array[0].logo);
        }
      }
    }
    // If there is no suggestions from our db, check clearbit
    if (!foundInDB) {
      try {
        const res = await axios.get('https://autocomplete.clearbit.com/v1/companies/suggest', {
          params: {
            query: change,
          },
        });
        this.$set(this.companies, 'array', res.data);
        if (this.linkedin && this.companies.array[0] && this.companies.array[0].logo) {
          this.$emit('set', 'companyLogo', this.companies.array[0].logo);
        }
      } catch (err) {
        // clearbit error
      }
    }
  }, 500);

  rangeAsc(a: number, b: number): number[] {
    const numbers = [] as number[];
    for (let i = a; i <= b; i += 1) {
      numbers.push(i);
    }
    return numbers;
  }

  rangeDesc(a: number, b: number): number[] {
    const numbers = [] as number[];
    for (let i = a; i > b; i -= 1) {
      numbers.push(i);
    }
    return numbers;
  }

  validate() {
    // @ts-ignore
    return this.$refs.form.validate();
  }
}
export default WorkExperienceForm;
