























































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import Logo from '../../shared/Logo.vue';
import { responseError } from '../../../utils/response-error';
import { education } from '../../../store/education';

@Component({
  components: {
    Logo,
  },
})
class LinkedInModal extends Vue {
  step: string = 'intro';
  linkedIn = '';
  errorMessage = '';
  loading = false;
  saving = false;
  items: any[] = [];

  get modalTitle() {
    const { step } = this;
    if (step === 'list') return 'Collected education';
    if (step === 'edit') return 'Edit education';
    return '';
  }

  get modalHasFooter() {
    const { step } = this;
    return step === 'list' || step === 'edit';
  }

  get allItemsChecked() {
    return this.items.filter((item) => item.checked).length === this.items.length;
  }

  get hasItemsChecked() {
    return this.items.filter((item) => item.checked).length > 0;
  }

  created() {
    this.linkedIn = this.$store.state.account.linkedIn || '';
  }

  setStep(step: string) {
    this.step = step;
  }

  formatListDate(item: any) {
    const { dateFrom, dateTo, currentlyStudyingHere } = item;
    if (currentlyStudyingHere && !dateFrom) {
      return 'Present';
    }
    if (!dateFrom && !dateTo) {
      return '';
    }
    const startYear = new Date(dateFrom).getFullYear();
    const endYear = new Date(dateTo).getFullYear();
    if (currentlyStudyingHere) {
      return `${startYear} - Present`;
    }
    return `${startYear} - ${endYear}`;
  }

  toggleItem(index: any, checked: boolean) {
    this.items[index].checked = checked;
  }

  toggleAllItems(checked: boolean) {
    this.items.forEach((item) => {
      item.checked = checked;
    });
  }

  addItems(items: any[]) {
    const formatDate = (dateString: string) => {
      if (dateString && dateString.indexOf('-') > 0) {
        return dateString.split('-').slice(0, 2).join('-');
      }
      return dateString;
    };
    this.items = items.map((item: any) => {
      return {
        ...item,
        id: -1,
        dateFrom: item.dateFrom && item.dateFrom.indexOf('0001') >= 0 ? '' : formatDate(item.dateFrom),
        dateTo: item.dateTo && item.dateTo.indexOf('0001') >= 0 ? '' : formatDate(item.dateTo),
        degree: item.degree || '',
        description: item.description || '',
        field: item.field || '',
        school: item.school || '',
        currentlyStudyingHere: item.currentlyStudyingHere || false,
        checked: true,
        source: 'linkedin',
      };
    });
  }

  async fetchData() {
    // @ts-ignore
    const valid = await this.$refs.form.validate();
    // eslint-disable-next-line
    if (!valid || this.linkedIn.indexOf('javascript:') >= 0) return;
    this.loading = true;
    try {
      await this.$store.dispatch('updateProfileLinkedIn', { linkedIn: this.linkedIn });
      const res = await this.$store.dispatch('getLinkedInExperience');
      const { educations = [] } = res.data || {};
      this.loading = false;
      if (!educations.length) {
        this.setStep('empty');
      } else {
        this.addItems(educations);
        this.setStep('list');
      }
    } catch (err) {
      this.errorMessage = responseError(err);
      this.loading = false;
    }
  }

  save() {
    if (!this.hasItemsChecked) return;
    this.saving = true;
    this.saveItems()
      .then(() => {
        this.saving = false;
        this.$store.dispatch(education.actions.AssignEducation, this.$store.state.account.username);
        this.$store.dispatch('tryToGetAccount');
        this.setStep('success');
      })
      .catch((err) => {
        this.errorMessage = responseError(err);
      });
  }

  saveItems() {
    return new Promise((resolve, reject) => {
      const itemsToSave = this.items.filter((item) => item.checked);
      const requests = itemsToSave.map((item) => {
        const newItem = {
          ...item,
        };
        return () => this.$store.dispatch(education.actions.CreateEducation, newItem);
      });

      const sendRequest = () => {
        const request = requests.shift();
        if (!request) {
          resolve();
          return;
        }
        request()
          .then(sendRequest)
          .catch((err) => {
            reject(err);
          });
      };

      sendRequest();
    });
  }
}
export default LinkedInModal;
