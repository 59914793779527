
































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import EditButton from '../shared/EditButton.vue';

@Component({
  components: {
    EditButton,
  },
})
class ProfileBlock extends Vue {
  @Prop({ type: String, default: '' }) title: string;
  @Prop({ type: String, default: '' }) description: string;
  @Prop({ type: String, default: '' }) titleInfo: string;
  @Prop({ type: Boolean, default: false }) addButton: boolean;
  @Prop({ type: Boolean, default: false }) editButton: boolean;

  get hasHeader() {
    return this.title || this.addButton || this.editButton || (this.$slots.title && this.$slots.title.length);
  }

  get hasDescription() {
    return this.description || (this.$slots.description && this.$slots.description.length);
  }

  get hasTitleInfo() {
    return this.titleInfo || (this.$slots['title-info'] && this.$slots['title-info'].length);
  }

  onAddButtonClick() {
    this.$emit('add');
  }

  onEditButtonClick() {
    this.$emit('edit');
  }
}
export default ProfileBlock;
