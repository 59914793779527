







































































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { format } from 'date-fns';
import { debounce } from 'lodash-es';

@Component
class EducationForm extends Vue {
  @Prop() education: any;

  schools = {
    array: [],
  };

  get school(): string {
    return this.education.school || '';
  }

  set school(school: string) {
    this.$emit('set', 'school', school);
  }

  get degree(): string {
    return this.education.degree || '';
  }

  set degree(degree: string) {
    this.$emit('set', 'degree', degree);
  }

  get field(): string {
    return this.education.field || '';
  }

  set field(field: string) {
    this.$emit('set', 'field', field);
  }

  get currentlyStudyingHere(): boolean {
    return this.education.currentlyStudyingHere || false;
  }

  set currentlyStudyingHere(currentlyStudyingHere: boolean) {
    this.$emit('set', 'currentlyStudyingHere', currentlyStudyingHere);
  }

  get startMonth(): number {
    return this.education.startMonth;
  }

  set startMonth(startMonth: number) {
    // @ts-ignore
    this.$emit('set', 'startMonth', parseInt(startMonth, 10));
  }

  get startYear(): number {
    return this.education.startYear;
  }

  set startYear(startYear: number) {
    // @ts-ignore
    this.$emit('set', 'startYear', parseInt(startYear, 10));
  }

  get endMonth(): number {
    return this.education.endMonth;
  }

  set endMonth(endMonth: number) {
    // @ts-ignore
    this.$emit('set', 'endMonth', parseInt(endMonth, 10));
  }

  get endYear(): number {
    return this.education.endYear;
  }

  set endYear(endYear: number) {
    // @ts-ignore
    this.$emit('set', 'endYear', parseInt(endYear, 10));
  }

  get description(): string {
    return this.education.description || '';
  }

  set description(description: string) {
    this.$emit('set', 'description', description);
  }

  get endDateIsLower(): boolean {
    const { currentlyStudyingHere, startYear, startMonth, endYear, endMonth } = this;
    if (currentlyStudyingHere || !startYear || !startMonth || !endYear || !endMonth) return false;
    const startDate = new Date(startYear, startMonth - 1, 0).getTime();
    const endDate = new Date(endYear, endMonth - 1, 0).getTime();
    return endDate < startDate;
  }

  @Watch('endDateIsLower')
  onDateIsLower(value: any) {
    this.$emit('endDateIsLower', value);
  }

  @Watch('school')
  schoolSetter(school: string) {
    if (school) this.sendRequest(school);
  }

  hitHandler(school: string) {
    if (school.includes('(') && school.includes(')')) {
      this.school = school.replace(/ *\([^)]*\) */g, '');
    }
  }

  monthToString(month: number): string {
    return format(new Date(0, month, 0), 'MMMM');
  }

  rangeAsc(a: number, b: number): number[] {
    const numbers = [] as number[];
    for (let i = a; i <= b; i += 1) {
      numbers.push(i);
    }
    return numbers;
  }

  rangeDesc(a: number, b: number): number[] {
    const numbers = [] as number[];
    for (let i = a; i > b; i -= 1) {
      numbers.push(i);
    }
    return numbers;
  }

  getCurrentYear() {
    return new Date().getFullYear();
  }

  sendRequest = debounce(async (change: string) => {
    // First check our db
    const response = await this.$store.dispatch('getSchoolSuggestion', change);
    if (response.schools != null) {
      const resData: any = [];
      response.schools.forEach((school: any) => {
        resData.push({
          name: `${school.name} (${school.count})`,
        });
      });
      if (resData.length > 0) {
        this.$set(this.schools, 'array', resData);
      }
    }
  }, 500);
}

export default EducationForm;
