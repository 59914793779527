






import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({})
class ProfileBlockWidgetButton extends Vue {
  @Prop({ type: String, default: '' }) widget: string;

  get widgetUrl() {
    return `https://docs.codersrank.io/widgets/${this.widget}`;
  }

  get isPrivateView() {
    return this.$store.getters.shouldShowPrivateView;
  }
}
export default ProfileBlockWidgetButton;
