






















































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Logo from '../../shared/Logo.vue';
import WorkExperienceForm from './WorkExperienceForm.vue';
import { responseError } from '../../../utils/response-error';
import { workexperience } from '../../../store/workexperience';

@Component({
  components: {
    Logo,
    WorkExperienceForm,
  },
})
class LinkedInModal extends Vue {
  @Prop({}) onboarding: boolean;

  step: string = this.onboarding ? 'onboarding' : 'intro';
  linkedIn = '';
  errorMessage = '';
  loading = false;
  saving = false;
  items: any[] = [];
  currentItemIndex: number = 0;

  get modalTitle() {
    const { step } = this;
    if (step === 'list') return 'Collected work experiences';
    if (step === 'edit') return 'Edit work experience';
    return '';
  }

  get modalHasFooter() {
    const { step } = this;
    return step === 'list' || step === 'edit' || step === 'onboarding';
  }

  get allItemsChecked() {
    return this.items.filter((item) => item.checked).length === this.items.length;
  }

  get hasItemsChecked() {
    return this.items.filter((item) => item.checked).length > 0;
  }

  get currentItem() {
    return this.items[this.currentItemIndex];
  }

  created() {
    this.linkedIn = this.$store.state.account.linkedIn || '';
  }

  setStep(step: string) {
    if (step === 'edit') {
      this.items = this.items.filter((item) => item.checked);
    }
    this.step = step;
  }

  formatListDate(item: any) {
    const { startDate, endDate, currentlyWorkingHere } = item;
    if (currentlyWorkingHere && !startDate) {
      return 'Present';
    }
    if (!startDate && !endDate) {
      return '';
    }
    const startYear = new Date(startDate).getFullYear();
    const endYear = new Date(endDate).getFullYear();
    if (currentlyWorkingHere) {
      return `${startYear} - Present`;
    }
    return `${startYear} - ${endYear}`;
  }

  toggleItem(index: any, checked: boolean) {
    this.items[index].checked = checked;
  }

  toggleAllItems(checked: boolean) {
    this.items.forEach((item) => {
      item.checked = checked;
    });
  }

  addItems(items: any[]) {
    this.items = items.map((item: any) => {
      let location = item.location || '';
      if (location.trim()[0] === ',') location = '';
      return {
        ...item,
        startDate: item.startDate && item.startDate.indexOf('0001') >= 0 ? '' : item.startDate,
        endDate: item.endDate && item.endDate.indexOf('0001') >= 0 ? '' : item.endDate,
        checked: true,
        highlightedTechnologies: item.highlightedTechnologies || [],
        otherTechnologies: item.otherTechnologies || [],
        companyLogo: '',
        description: item.description || '',
        currentlyWorkingHere: item.currentlyWorkingHere || false,
        location,
        source: 'linkedin',
      };
    });
  }

  async fetchData() {
    // @ts-ignore
    const valid = await this.$refs.form.validate();
    // eslint-disable-next-line
    if (!valid || this.linkedIn.indexOf('javascript:') >= 0) return;
    this.loading = true;
    try {
      await this.$store.dispatch('updateProfileLinkedIn', { linkedIn: this.linkedIn });
      const res = await this.$store.dispatch('getLinkedInExperience');
      const items = (res.data || {}).workExperiences || [];
      this.loading = false;
      if (!items || !items.length) {
        this.setStep('empty');
      } else {
        if (this.onboarding) {
          this.$emit('data', res.data || {});
          this.$emit('close');
          return;
        }
        this.addItems(items);
        this.setStep('list');
      }
    } catch (err) {
      this.errorMessage = responseError(err);
      this.loading = false;
    }
  }

  updateCurrentItem(field: string, value: any) {
    this.currentItem[field] = value;
  }

  async nextItem() {
    // @ts-ignore
    const valid = await this.$refs.form.validate();
    if (!valid) return;
    if (this.items.length === this.currentItemIndex + 1) {
      this.saving = true;
      this.saveItems()
        .then(() => {
          this.saving = false;
          if (!this.onboarding) {
            this.$store.dispatch('getProfile', { type: 'user', username: this.$store.state.account.username });
          }
          if (!this.onboarding) {
            this.setStep('success');
          } else {
            this.$emit('close');
            this.$emit('success');
          }
        })
        .catch((err) => {
          this.errorMessage = responseError(err);
        });
      return;
    }
    this.currentItemIndex += 1;
  }

  saveItems() {
    return new Promise((resolve, reject) => {
      const requests = this.items.map((item) => {
        const newItem = {
          ...item,
        };
        delete newItem.id;
        return () => this.$store.dispatch(workexperience.actions.CreateWorkExperience, newItem);
      });

      const sendRequest = () => {
        const request = requests.shift();
        if (!request) {
          resolve();
          return;
        }
        request()
          .then(sendRequest)
          .catch((err) => {
            reject(err);
          });
      };

      sendRequest();
      this.$store.dispatch('tryToGetAccount');
    });
  }
}
export default LinkedInModal;
