










































import Vue from 'vue';
import Component from 'vue-class-component';
import { workexperience } from '../../store/workexperience';
import CompanyCard from './WorkExperience/CompanyCard.vue';
import AddEditModal from './WorkExperience/AddEditModal.vue';
import LinkedinModal from './WorkExperience/LinkedinModal.vue';
import ProfileBlock from './ProfileBlock.vue';
import ProfileBlockWidgetButton from './ProfileBlockWidgetButton.vue';

@Component({
  components: {
    ProfileBlock,
    CompanyCard,
    AddEditModal,
    LinkedinModal,
    ProfileBlockWidgetButton,
  },
})
class WorkExperience extends Vue {
  linkedInModalOpened = false;
  showAll = false;

  get isAsOthersSee() {
    return this.$route.name && this.$route.name.indexOf('AsOthersSee') > 0;
  }

  get hasMore() {
    return this.workexperiences.length > 3 && !this.showAll;
  }

  get workexperiences() {
    return this.$store.getters[workexperience.getters.WorkExperience];
  }

  get workexperiencesDisplay() {
    if (!this.showAll) return this.workexperiences.slice(0, 3);
    return this.workexperiences;
  }

  get showLinkedin() {
    return !this.$store.state.hideLinkedin;
  }

  openAddModal() {
    this.$store.commit(workexperience.mutations.OpenAddModal);
  }

  closeModal() {
    this.$store.commit(workexperience.mutations.CloseModal);
  }

  goCompanyLeaderboard() {
    this.$router.replace({ name: 'companyLeaderboard' });
  }
}
export default WorkExperience;
