









































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { format } from 'date-fns';
import { getUTCDate } from '../../../utils/date';
import { Portfolio, portfolio } from '../../../store/portfolio';
import DataCard from '../../shared/DataCard.vue';

@Component({
  components: {
    DataCard,
  },
})
class PortfolioCard extends Vue {
  @Prop({ type: Object, default: () => ({}) }) portfolio: Portfolio;

  get isAsOthersSee() {
    return this.$route.name && this.$route.name.indexOf('AsOthersSee') > 0;
  }

  openEditModal(id: number) {
    this.$store.dispatch(portfolio.actions.OpenEditPortfolioModal, id);
  }

  formatDate(date: string): string {
    return format(getUTCDate(date), 'MMM yyyy');
  }

  get picture() {
    const { picture, picturePreview } = this.portfolio;
    if (picture && typeof picture === 'string') return picture;
    if (picturePreview && typeof picturePreview === 'string') return picturePreview;
    return undefined;
  }

  isDateValid(date: string | undefined): boolean {
    if (!date) return false;
    return new Date(date) > new Date('1970-01-01');
  }

  get dateRangeText() {
    const { startDate, endDate, currentlyWorkingHere } = this.portfolio;
    let text = '';
    if (!startDate) {
      return text;
    }
    if (this.isDateValid(startDate)) {
      text += this.formatDate(startDate);
    }
    if (currentlyWorkingHere) {
      text += ' - Present';
    } else if (this.isDateValid(endDate)) {
      // @ts-ignore since isValid will check for undefined
      text += ` - ${this.formatDate(endDate)}`;
    }
    return text;
  }

  removePortfolio() {
    this.$store.dispatch(portfolio.actions.DeletePortfolio, this.portfolio.id);
  }
}
export default PortfolioCard;
