var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"form",attrs:{"tag":"form"}},[(!_vm.linkedin)?_c('validation-provider',{staticClass:"form-group",attrs:{"rules":"required|max:100","name":"title","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label"},[_vm._v("Title *")]),_vm._v(" "),_c('cr-input',{attrs:{"id":"title","placeholder":"ex: Manager","name":"title","state":errors[0] ? false : null},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),_vm._v(" "),_c('span',{staticClass:"form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3204277956)}):_vm._e(),_vm._v(" "),(!_vm.linkedin)?_c('validation-provider',{staticClass:"form-group",attrs:{"rules":"required|max:100","name":"company","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label"},[_vm._v("Company *")]),_vm._v(" "),_c('cr-select',{staticClass:"company-select",attrs:{"options":_vm.companies.array,"format":function (s) { return s.name; },"placeholder":"ex: CodersRank","name":"company","state":errors[0] ? false : null},on:{"select":function($event){return _vm.companyHitHandler($event)}},model:{value:(_vm.companyName),callback:function ($$v) {_vm.companyName=$$v},expression:"companyName"}}),_vm._v(" "),_c('span',{staticClass:"form-error"},[_vm._v(_vm._s(errors[0]))]),_vm._v(" "),_c('div',{staticClass:"company-image"},[_c('img',{attrs:{"src":_vm.companyLogo,"alt":""}}),_vm._v(" "),_c('div',{staticClass:"change-logo",on:{"click":function($event){return _vm.triggerFileUpload()}}},[_vm._v("Change logo")]),_vm._v(" "),_c('input',{ref:"companyLogoUpload",attrs:{"type":"file","hidden":""},on:{"change":function($event){return _vm.fileUploadHandler($event)}}})])]}}],null,false,1972448413)}):_vm._e(),_vm._v(" "),_c('label',{staticClass:"form-label"},[_vm._v("Location *")]),_vm._v(" "),_c('div',[_c('cr-checkbox',{attrs:{"label":true},model:{value:(_vm.isRemote),callback:function ($$v) {_vm.isRemote=$$v},expression:"isRemote"}},[_c('span',[_vm._v("Remote")])])],1),_vm._v(" "),(!_vm.isRemote)?_c('validation-provider',{staticClass:"form-group",attrs:{"rules":"required|max:200","name":"location","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.allowCustomLocation)?_c('cr-input',{attrs:{"type":"text","name":"location","value":_vm.location,"placeholder":"ex: London, United Kingdom"},on:{"input":_vm.updateCustomLocation},model:{value:(_vm.location),callback:function ($$v) {_vm.location=$$v},expression:"location"}}):_c('location-input',{attrs:{"city":_vm.city,"country":_vm.country,"state":errors[0] ? false : null},on:{"updateLocation":_vm.updateLocation},model:{value:(_vm.location),callback:function ($$v) {_vm.location=$$v},expression:"location"}}),_vm._v(" "),(errors[0])?_c('span',{staticClass:"form-error"},[_vm._v("The location format is incorrect. Please select from the drop-down list of suggestions.")]):_vm._e()]}}],null,false,2015376038)}):_vm._e(),_vm._v(" "),(!_vm.linkedin)?[_c('div',{staticClass:"form-group currently-working-here"},[_c('cr-checkbox',{attrs:{"label":true},model:{value:(_vm.currentlyWorkingHere),callback:function ($$v) {_vm.currentlyWorkingHere=$$v},expression:"currentlyWorkingHere"}},[_c('span',[_vm._v("I am currently working in this role")])])],1),_vm._v(" "),_c('div',{staticClass:"form-group row"},[_c('div',{staticClass:"start-date col-sm-6"},[_c('label',{staticClass:"form-label"},[_vm._v("Start date *")]),_vm._v(" "),_c('validation-provider',{attrs:{"name":"start date month","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cr-input',{attrs:{"type":"select","name":"start date month"},model:{value:(_vm.startDateMonth),callback:function ($$v) {_vm.startDateMonth=$$v},expression:"startDateMonth"}},[_c('option',{attrs:{"disabled":"","selected":""},domProps:{"value":undefined}},[_vm._v("Month")]),_vm._v(" "),_vm._l((_vm.rangeAsc(1, _vm.getMaxPossibleStartMonth)),function(key){return _c('option',{key:key,domProps:{"value":key}},[_vm._v(_vm._s(_vm.monthToString(key)))])})],2),_vm._v(" "),_c('span',{staticClass:"form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2446260650)}),_vm._v(" "),_c('validation-provider',{attrs:{"name":"start date year","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cr-input',{attrs:{"type":"select","name":"start date year"},model:{value:(_vm.startDateYear),callback:function ($$v) {_vm.startDateYear=$$v},expression:"startDateYear"}},[_c('option',{attrs:{"disabled":"","selected":""},domProps:{"value":undefined}},[_vm._v("Year")]),_vm._v(" "),_vm._l((_vm.rangeDesc(_vm.getCurrentYear(), 1980)),function(key){return _c('option',{key:key,domProps:{"value":key}},[_vm._v(_vm._s(key))])})],2),_vm._v(" "),_c('span',{staticClass:"form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,990667173)})],1),_vm._v(" "),(!_vm.currentlyWorkingHere)?_c('div',{staticClass:"end-date col-sm-6"},[_c('label',{staticClass:"form-label"},[_vm._v("End date *")]),_vm._v(" "),_c('validation-provider',{attrs:{"name":"end date month","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cr-input',{attrs:{"type":"select","name":"end date month"},model:{value:(_vm.endDateMonth),callback:function ($$v) {_vm.endDateMonth=$$v},expression:"endDateMonth"}},[_c('option',{attrs:{"disabled":"","selected":""},domProps:{"value":undefined}},[_vm._v("Month")]),_vm._v(" "),_vm._l((_vm.rangeAsc(1, _vm.getMaxPossibleEndMonth)),function(key){return _c('option',{key:key,domProps:{"value":key}},[_vm._v(_vm._s(_vm.monthToString(key)))])})],2),_vm._v(" "),_c('span',{staticClass:"form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,998543301)}),_vm._v(" "),_c('validation-provider',{attrs:{"name":"end date year","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cr-input',{attrs:{"type":"select","name":"end date year"},model:{value:(_vm.endDateYear),callback:function ($$v) {_vm.endDateYear=$$v},expression:"endDateYear"}},[_c('option',{attrs:{"disabled":"","selected":""},domProps:{"value":undefined}},[_vm._v("Year")]),_vm._v(" "),_vm._l((_vm.rangeDesc(_vm.getCurrentYear(), 1980)),function(key){return _c('option',{key:key,domProps:{"value":key}},[_vm._v(_vm._s(key))])})],2),_vm._v(" "),_c('span',{staticClass:"form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3966218821)})],1):_vm._e()]),_vm._v(" "),(!_vm.areDatesValid)?_c('span',{staticClass:"error form-error"},[_vm._v("The end date must not be less than the start date.")]):_vm._e(),_vm._v(" "),_c('validation-provider',{staticClass:"form-group",attrs:{"rules":"required|max:2000","name":"description","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label"},[_vm._v("Description *")]),_vm._v(" "),_c('cr-input',{attrs:{"type":"textarea","name":"description","state":errors[0] ? false : null},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_vm._v(" "),_c('span',{staticClass:"form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2018903804)})]:_vm._e(),_vm._v(" "),(_vm.mergeTechnologies)?[_c('div',{staticClass:"form-group"},[_c('skills-experience-select',{attrs:{"title":"Highlighted Technologies","text":"Add your top technologies you used the most","experience-select":false,"placeholder":"Highlighted Technologies","tag-placeholder":"Add this skill","suggest-skills":"","fetch-options":_vm.fetchSkills},model:{value:(_vm.technologies),callback:function ($$v) {_vm.technologies=$$v},expression:"technologies"}})],1)]:[_c('div',{staticClass:"form-group"},[_c('skills-experience-select',{attrs:{"title":"Highlighted Technologies","text":"Add your top 3 technologies you used the most","experience-select":false,"placeholder":"Highlighted Technologies","tag-placeholder":"Add this skill","max-tags":3,"suggest-skills":"","fetch-options":_vm.fetchSkills},model:{value:(_vm.highlightedTechnologies),callback:function ($$v) {_vm.highlightedTechnologies=$$v},expression:"highlightedTechnologies"}})],1),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('skills-experience-select',{attrs:{"title":"Other Technologies","text":"Add any other technologies you used in this company.","experience-select":false,"placeholder":"Other Technologies","tag-placeholder":"Add this skill","suggest-skills":"","fetch-options":_vm.fetchSkills},model:{value:(_vm.otherTechnologies),callback:function ($$v) {_vm.otherTechnologies=$$v},expression:"otherTechnologies"}})],1)],_vm._v(" "),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }