














import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { format } from 'date-fns';
import { Education, education } from '../../../store/education';
import { getUTCDate } from '../../../utils/date';
import DataCard from '../../shared/DataCard.vue';

@Component({
  components: {
    DataCard,
  },
})
class EducationCard extends Vue {
  @Prop() education: Education;

  get isAsOthersSee() {
    return this.$route.name && this.$route.name.indexOf('AsOthersSee') > 0;
  }

  get degreeAndField() {
    return [this.education.degree, this.education.field].filter((value) => value && value.trim()).join(', ');
  }

  openEditModal() {
    this.$store.dispatch(education.actions.OpenEditEducationModal, this.education.id);
  }

  formatDateRange(dateFrom: string, dateTo: string) {
    let dateFromFormated;
    let dateToFormated;
    try {
      dateFromFormated = format(getUTCDate(dateFrom), 'MMM yyyy');
    } catch (err) {
      dateFromFormated = format(new Date(), 'MMM yyyy');
    }
    try {
      dateToFormated = format(getUTCDate(dateTo), 'MMM yyyy');
    } catch (err) {
      dateToFormated = format(new Date(), 'MMM yyyy');
    }
    if (this.education.currentlyStudyingHere) {
      return `${dateFromFormated} - Present`;
    }
    return `${dateFromFormated} - ${dateToFormated}`;
  }

  editHandler() {
    this.$store.dispatch(education.actions.OpenEditEducationModal, this.education.id);
  }

  removeHandler() {
    this.$store.dispatch(education.actions.DeleteEducation, this.education.id);
  }
}
export default EducationCard;
