








































import Vue from 'vue';
import Component from 'vue-class-component';
import { portfolio } from '../../store/portfolio';
import PortfolioCard from './Portfolio/PortfolioCard.vue';
import AddEditModal from './Portfolio/AddEditModal.vue';
import LinkedinModal from './Portfolio/LinkedinModal.vue';
import ProfileBlock from './ProfileBlock.vue';
import ProfileBlockWidgetButton from './ProfileBlockWidgetButton.vue';

@Component({
  components: {
    ProfileBlock,
    PortfolioCard,
    AddEditModal,
    LinkedinModal,
    ProfileBlockWidgetButton,
  },
})
class Portfolio extends Vue {
  linkedInModalOpened = false;
  showAll = false;

  get isAsOthersSee() {
    return this.$route.name && this.$route.name.indexOf('AsOthersSee') > 0;
  }

  get hasMore() {
    return this.portfolios.length > 3 && !this.showAll;
  }

  get portfolios() {
    return this.$store.state.portfolio.portfolios;
  }

  get portfoliosDisplay() {
    if (!this.showAll) return this.portfolios.slice(0, 3);
    return this.portfolios;
  }

  get showLinkedin() {
    return !this.$store.state.hideLinkedin;
  }

  openAddModal() {
    this.$store.dispatch(portfolio.actions.OpenAddPortfolioModal);
  }

  closeModal() {
    this.$store.dispatch(portfolio.actions.ClosePortfolioModal);
  }
}
export default Portfolio;
