
















































































import Vue from 'vue';
import Component from 'vue-class-component';
import { education } from '../../store/education';
import ProfileBlock from './ProfileBlock.vue';
import EducationCard from './Education/EducationCard.vue';
import CertificateCard from './Education/CertificateCard.vue';
import AddEditCertificateModal from './Education/AddEditCertificateModal.vue';
import AddEditEducationModal from './Education/AddEditEducationModal.vue';
import LinkedinModal from './Education/LinkedinModal.vue';
import LinkedinModalCertificates from './Education/LinkedinModalCertificates.vue';
import ProfileBlockWidgetButton from './ProfileBlockWidgetButton.vue';

@Component({
  components: {
    ProfileBlock,
    EducationCard,
    CertificateCard,
    AddEditEducationModal,
    AddEditCertificateModal,
    LinkedinModal,
    LinkedinModalCertificates,
    ProfileBlockWidgetButton,
  },
})
class Education extends Vue {
  linkedInModalOpened = false;
  linkedInCertificatesModalOpened = false;
  showAllCertificates = false;

  get isAsOthersSee() {
    return this.$route.name && this.$route.name.indexOf('AsOthersSee') > 0;
  }

  get educations() {
    return this.$store.state.education.educations;
  }

  get hasMoreCertificates() {
    return this.certificates.length > 3 && !this.showAllCertificates;
  }

  get certificates() {
    return this.$store.state.education.certificates;
  }

  get certificatesDisplay() {
    if (!this.showAllCertificates) return this.certificates.slice(0, 3);
    return this.certificates;
  }

  get showLinkedin() {
    return !this.$store.state.hideLinkedin;
  }

  openAddEducationModal() {
    this.$store.dispatch(education.actions.OpenAddEducationModal);
  }

  openAddCertificateModal() {
    this.$store.dispatch(education.actions.OpenAddCertificateModal);
  }

  closeEducationModal() {
    this.$store.dispatch(education.actions.CloseEducationModal);
  }

  closeCertificateModal() {
    this.$store.dispatch(education.actions.CloseCertificateModal);
  }
}
export default Education;
