var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"form",attrs:{"slim":""}},[_c('validation-provider',{staticClass:"form-group",attrs:{"tag":"div","rules":"required|max:100","name":"School"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label"},[_vm._v("School/University *")]),_vm._v(" "),_c('cr-select',{attrs:{"options":_vm.schools.array.map(function (s) { return s.name; }),"placeholder":"School","name":"School","state":errors[0] ? false : null},on:{"select":function($event){return _vm.hitHandler($event)}},model:{value:(_vm.school),callback:function ($$v) {_vm.school=$$v},expression:"school"}}),_vm._v(" "),_c('span',{staticClass:"form-error"},[_vm._v(_vm._s(errors[0]))])]}}])}),_vm._v(" "),_c('validation-provider',{staticClass:"form-group",attrs:{"tag":"div","rules":"required","name":"Degree"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label"},[_vm._v("Degree *")]),_vm._v(" "),_c('cr-input',{attrs:{"placeholder":"Bachelor","name":"Degree","state":errors[0] ? false : null},model:{value:(_vm.degree),callback:function ($$v) {_vm.degree=$$v},expression:"degree"}}),_vm._v(" "),_c('span',{staticClass:"form-error"},[_vm._v(_vm._s(errors[0]))])]}}])}),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-label"},[_vm._v("Field of study")]),_vm._v(" "),_c('cr-input',{attrs:{"placeholder":"Computer Programming"},model:{value:(_vm.field),callback:function ($$v) {_vm.field=$$v},expression:"field"}})],1),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('cr-checkbox',{attrs:{"label":true},model:{value:(_vm.currentlyStudyingHere),callback:function ($$v) {_vm.currentlyStudyingHere=$$v},expression:"currentlyStudyingHere"}},[_c('span',[_vm._v("I’m still learning here")])])],1),_vm._v(" "),_c('div',{staticClass:"form-group row"},[_c('div',{staticClass:"col-sm-6"},[_c('label',{staticClass:"form-label"},[_vm._v("Start date *")]),_vm._v(" "),_c('validation-provider',{attrs:{"name":"Start Month","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cr-input',{attrs:{"type":"select","name":"Start Month"},model:{value:(_vm.startMonth),callback:function ($$v) {_vm.startMonth=$$v},expression:"startMonth"}},[_c('option',{attrs:{"disabled":"","selected":""},domProps:{"value":null}},[_vm._v("Month")]),_vm._v(" "),_vm._l((_vm.rangeAsc(1, 12)),function(key){return _c('option',{key:key,domProps:{"value":key}},[_vm._v(_vm._s(_vm.monthToString(key)))])})],2),_vm._v(" "),_c('span',{staticClass:"form-error"},[_vm._v(_vm._s(errors[0]))])]}}])}),_vm._v(" "),_c('validation-provider',{attrs:{"name":"Start Year","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cr-input',{attrs:{"type":"select","name":"Start Year"},model:{value:(_vm.startYear),callback:function ($$v) {_vm.startYear=$$v},expression:"startYear"}},[_c('option',{attrs:{"disabled":"","selected":""},domProps:{"value":null}},[_vm._v("Year")]),_vm._v(" "),_vm._l((_vm.rangeDesc(_vm.getCurrentYear(), 1980)),function(key){return _c('option',{key:key,domProps:{"value":key}},[_vm._v(_vm._s(key))])})],2),_vm._v(" "),_c('span',{staticClass:"form-error"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_vm._v(" "),(!_vm.currentlyStudyingHere)?_c('div',{staticClass:"col-sm-6"},[_c('label',{staticClass:"form-label"},[_vm._v("End date *")]),_vm._v(" "),_c('validation-provider',{attrs:{"name":"End Month","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cr-input',{attrs:{"type":"select","name":"End Month"},model:{value:(_vm.endMonth),callback:function ($$v) {_vm.endMonth=$$v},expression:"endMonth"}},[_c('option',{attrs:{"disabled":"","selected":""},domProps:{"value":null}},[_vm._v("Month")]),_vm._v(" "),_vm._l((_vm.rangeAsc(1, 12)),function(key){return _c('option',{key:key,domProps:{"value":key}},[_vm._v(_vm._s(_vm.monthToString(key)))])})],2),_vm._v(" "),_c('span',{staticClass:"form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,805990471)}),_vm._v(" "),_c('validation-provider',{attrs:{"name":"End Year","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cr-input',{attrs:{"type":"select","name":"End Year"},model:{value:(_vm.endYear),callback:function ($$v) {_vm.endYear=$$v},expression:"endYear"}},[_c('option',{attrs:{"disabled":"","selected":""},domProps:{"value":null}},[_vm._v("Year")]),_vm._v(" "),_vm._l((_vm.rangeDesc(_vm.getCurrentYear() + 6, 1980)),function(key){return _c('option',{key:key,domProps:{"value":key}},[_vm._v(_vm._s(key))])})],2),_vm._v(" "),_c('span',{staticClass:"form-error"},[_vm._v(_vm._s(errors[0]))]),_vm._v(" "),(_vm.endDateIsLower)?_c('span',{staticClass:"form-error"},[_vm._v("End date should be later than the start date")]):_vm._e()]}}],null,false,4140864767)})],1):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-label"},[_vm._v("Description")]),_vm._v(" "),_c('cr-input',{attrs:{"type":"textarea","name":"Description"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }