






























import Vue from 'vue';
import Component from 'vue-class-component';
import { portfolio } from '../../../store/portfolio';
import { getUTCDate } from '../../../utils/date';
import PortfolioForm from './PortfolioForm.vue';

@Component({
  components: {
    PortfolioForm,
  },
})
class AddEditModal extends Vue {
  formChanged = false;
  id = -1;
  areDatesValid = true;

  portfolio: any = {
    picture: '',
    picturePreview: '',
    title: '',
    companyName: '',

    role: '',
    description: '',
    highlightedSkills: [],
    skills: [],

    currentlyWorkingHere: false,
    startDateMonth: null,
    startDateYear: null,
    endDateMonth: null,
    endDateYear: null,

    demoUrl: '',
    sourceUrl: '',
  };

  updatePortfolio(field: string, value: any) {
    const { companyName } = this.portfolio;
    if ((field === 'companyName' && value !== companyName) || field !== 'companyName') {
      this.formChanged = true;
    }
    this.portfolio[field] = value;
  }

  saveHandler() {
    // @ts-ignore
    this.$refs.form.validate().then((isValid) => {
      if (isValid && this.areDatesValid) {
        const portfolioToModify = {
          ...this.portfolio,
        };
        portfolioToModify.id = this.id;

        if (this.id < 0 || typeof this.id === 'undefined') {
          this.$store.dispatch(portfolio.actions.CreatePortfolio, portfolioToModify);
          this.$store.dispatch('tryToGetAccount');
        } else {
          this.$store.dispatch(portfolio.actions.EditPortfolio, portfolioToModify);
        }
      }
    });
  }

  deleteHandler() {
    this.$store.dispatch(portfolio.actions.DeletePortfolio, this.id);
  }

  isDateValid(date: string): boolean {
    if (!date) return false;
    return new Date(date) > new Date('1970-01-01');
  }

  created() {
    const selectedPortfolio = this.$store.getters[portfolio.getters.SelectedPortfolio];
    this.portfolio = {
      ...this.portfolio,
      ...selectedPortfolio,
    };
    this.id = typeof selectedPortfolio.id === 'undefined' ? -1 : selectedPortfolio.id;

    this.portfolio.startDateMonth = this.isDateValid(selectedPortfolio.startDate)
      ? getUTCDate(selectedPortfolio.startDate).getMonth() + 1
      : null;
    this.portfolio.startDateYear = this.isDateValid(selectedPortfolio.startDate)
      ? getUTCDate(selectedPortfolio.startDate).getFullYear()
      : null;
    this.portfolio.endDateMonth = this.isDateValid(selectedPortfolio.endDate)
      ? getUTCDate(selectedPortfolio.endDate).getMonth() + 1
      : null;
    this.portfolio.endDateYear = this.isDateValid(selectedPortfolio.endDate)
      ? getUTCDate(selectedPortfolio.endDate).getFullYear()
      : null;
  }
}
export default AddEditModal;
