














































































import Vue from 'vue';
import Component from 'vue-class-component';
import { format } from 'date-fns';
import { education } from '../../../store/education';
import { getUTCDate } from '../../../utils/date';

@Component({})
class AddEditCertificateModal extends Vue {
  formChanged = false;
  id = -1;
  name = '';
  organization = '';
  field = '';
  month: number | null = null;
  year: number | null = null;
  certificationURL = '';
  certificationID = '';

  mounted() {
    const cert = this.$store.getters[education.getters.SelectedCertificate];
    this.id = cert.id;
    this.name = cert.name || '';
    this.organization = cert.organization || '';
    this.field = cert.field || '';
    this.month = cert.date ? getUTCDate(cert.date).getMonth() + 1 : null;
    this.year = cert.date ? getUTCDate(cert.date).getFullYear() : null;
    this.certificationURL = cert.certificationURL || '';
    this.certificationID = cert.certificationID || '';
  }

  saveHandler() {
    // @ts-ignore
    this.$refs.form.validate().then((valid) => {
      if (!valid) return;

      const cert = {
        id: this.id,
        name: this.name,
        organization: this.organization,
        date: this.year && this.month ? `${this.year}-${this.month.toString().padStart(2, '0')}` : undefined,
        certificationURL: this.certificationURL,
        certificationID: this.certificationID,
      };

      if (this.$store.state.education.modalCertificate === 'add') {
        this.$store.dispatch(education.actions.CreateCertificate, cert);
        this.$store.dispatch('tryToGetAccount');
      } else {
        this.$store.dispatch(education.actions.EditCertificate, cert);
      }
    });
  }

  deleteHandler() {
    this.$store.dispatch(education.actions.DeleteCertificate, this.id);
  }

  monthToString(month: number): string {
    return format(new Date(0, month, 0), 'MMMM');
  }

  rangeAsc(a: number, b: number): number[] {
    const numbers = [] as number[];
    for (let i = a; i <= b; i += 1) {
      numbers.push(i);
    }
    return numbers;
  }

  rangeDesc(a: number, b: number): number[] {
    const numbers = [] as number[];
    for (let i = a; i > b; i -= 1) {
      numbers.push(i);
    }
    return numbers;
  }

  getCurrentYear() {
    return new Date().getFullYear();
  }
}
export default AddEditCertificateModal;
