
































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  model: {
    event: 'change',
    prop: 'value',
  },
})
class SkillsExperienceSelect extends Vue {
  @Prop({ type: String, default: '' }) title: string;
  @Prop({ type: String, default: '' }) text: string;
  @Prop({ type: String, default: '' }) experienceText: string;
  @Prop({ type: String, default: '' }) placeholder: string;
  @Prop({ type: String, default: '' }) tagPlaceholder: string;
  @Prop({ type: Array, default: () => [] }) value: any[];
  @Prop({ type: Array, default: () => [] }) options: any[];
  @Prop({ type: Function, default: undefined }) fetchOptions: (query: string, callback: any) => any[];
  @Prop({ type: String, default: '' }) propName: string;
  @Prop({ type: Boolean, default: true }) allowCustomTags: boolean;
  @Prop({ type: Number, default: 0 }) maxTags: number;
  @Prop({ type: Boolean }) loading: boolean;
  @Prop({ type: Boolean }) error: boolean;
  @Prop({ type: String, default: '' }) wrongSkill: string;
  @Prop({ type: Boolean, default: false }) customSkillConfirm: boolean;
  @Prop({ type: Boolean, default: false }) suggestSkills: boolean;
  @Prop({ type: Boolean, default: true }) experienceSelect: boolean;
  @Prop({ type: Number, default: 0 }) minExperience: number;
  @Prop({ type: Boolean, default: true }) taggableKeepFocus: boolean;

  skills: string[] = [...(this.value || []).map((s) => (typeof s === 'string' ? s : s[this.propName]))];
  skillsToSend: any[] = [...(this.value || [])];
  yearsOptions: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
  confirmModalOpened = false;
  confirmModalValue: string = '';
  skillsLoading = false;
  suggestedSkills: string[] = [];

  get suggestedSkillsUnique() {
    return this.suggestedSkills.filter((skill) => !this.skills.includes(skill));
  }

  created() {
    // Allow reactivity in case of experience doesn't sent from backend
    this.value.forEach((skill) => {
      if (typeof skill === 'string') return;
      if (!skill.experience) {
        this.$set(skill, 'experience', this.minExperience);
      }
    });
    this.skillsToSend = this.value;
  }

  onSelectInput(value: string) {
    this.$emit('input', value);
  }

  onSelectChange(values: string[] = []) {
    if (!this.experienceSelect) {
      this.skillsToSend = [...values];
      this.$emit('change', this.skillsToSend);
      this.fetchSuggestedSkills();
      return;
    }
    const before = this.skillsToSend.length;
    // remove skills
    for (let i = this.skillsToSend.length - 1; i >= 0; i -= 1) {
      if (typeof this.skillsToSend[i] === 'string') {
        if (values.indexOf(this.skillsToSend[i]) < 0) {
          this.skillsToSend.splice(i, 1);
        }
      } else if (values.indexOf(this.skillsToSend[i][this.propName]) < 0) {
        this.skillsToSend.splice(i, 1);
      }
    }

    // add skills
    if (this.experienceSelect) {
      values.forEach((skillName) => {
        if (this.skillsToSend.filter((skill) => skill[this.propName] === skillName).length === 0) {
          this.addExperienceToSkill(skillName, this.minExperience);
        }
      });
    }
    const after = this.skillsToSend.length;

    if (before !== after) {
      this.$emit('change', this.skillsToSend);
      this.fetchSuggestedSkills();
    }
  }

  addExperienceToSkill(skill: string, experience: number, emit?: boolean) {
    if (this.skillsToSend.filter((s) => s[this.propName] === skill).length) {
      // @ts-ignore
      this.skillsToSend.find((s) => s[this.propName] === skill).experience = experience;
    } else {
      this.skillsToSend.push({
        [this.propName]: skill,
        experience,
      });
      const skills = [...this.skills];
      this.$set(this, 'skills', []);
      this.$nextTick(() => {
        this.$set(this, 'skills', skills);
      });
    }
    if (emit) {
      this.$emit('change', this.skillsToSend);
    }
  }

  isZeroYears(skill: any) {
    return this.skillsToSend.find((s) => s[this.propName] === skill).experience === 0;
  }

  onConfirmLinkMouseEnter() {
    const el = this.$el.querySelector('.cr-dropdown-list-item-highlighted');
    if (!el) return;
    el.classList.remove('cr-dropdown-list-item-highlighted');
  }

  openConfirmModal() {
    this.confirmModalOpened = true;
    this.confirmModalValue = '';
  }

  closeConfirmModal() {
    this.confirmModalOpened = false;
  }

  addConfirmedValue() {
    this.skills = [...this.skills, this.confirmModalValue];
    this.onSelectChange(this.skills);
    this.closeConfirmModal();
  }

  fetchSuggestedSkills() {
    this.$nextTick(() => {
      if (!this.suggestSkills) return;
      const skills = this.value.map((v) => (typeof v === 'string' ? v : v.skill));
      if (!skills.length) {
        this.suggestedSkills = [];
        return;
      }
      this.$store.dispatch('getRelatedSkillSuggestion', skills).then((data) => {
        if (this.suggestedSkills.length && (!data || !Array.isArray(data) || !data.length)) return;
        this.suggestedSkills = data;
      });
    });
  }

  addSuggestedSkill(skill: string) {
    this.skills = [...this.skills, skill];
    this.onSelectChange(this.skills);
  }

  removeSkill(skill: string) {
    if (this.skills.indexOf(skill) >= 0) {
      this.skills.splice(this.skills.indexOf(skill), 1);
      this.onSelectChange(this.skills);
    }
  }

  mounted() {
    if (this.value && this.value.length) {
      this.fetchSuggestedSkills();
    }
  }
}

export default SkillsExperienceSelect;
