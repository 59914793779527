






























import Vue from 'vue';
import Component from 'vue-class-component';
import ProfileBlock from './ProfileBlock.vue';
import languagesList from '../../utils/languages-list';
import AboutEditModal from './AboutEditModal.vue';
import AboutLinkedinModal from './AboutLinkedinModal.vue';

@Component({
  components: {
    ProfileBlock,
    AboutEditModal,
    AboutLinkedinModal,
  },
})
class About extends Vue {
  modalOpened = false;
  linkedInModalOpened = false;
  proficiencyList: any = {
    elementary: 'Elementary proficiency',
    limited: 'Limited working proficiency',
    professional: 'Professional proficiency',
    full: 'Full professional proficiency',
    native: 'Native or bilingual proficiency',
  };

  get isAsOthersSee() {
    return this.$route.name && this.$route.name.indexOf('AsOthersSee') > 0;
  }

  get isPrivateProfile() {
    return this.$store.state.isLoggedIn && this.$store.getters.shouldShowPrivateView;
  }

  get showAboutSection() {
    return (
      (this.about !== '' && typeof this.about !== 'undefined') ||
      (this.languages && this.languages.length) ||
      this.isPrivateProfile
    );
  }

  get about() {
    return this.$store.state.profile.ownProfile ? this.$store.state.account.about : this.$store.state.profile.about;
  }

  get languages() {
    const languages = this.$store.state.profile.ownProfile
      ? this.$store.getters.account.languages
      : this.$store.getters.profile.languages;
    return languages || [];
  }

  get showLinkedin() {
    return !this.$store.state.hideLinkedin;
  }

  languageName(code: string) {
    const language: any = languagesList.filter((l) => l.code === code)[0];
    return `${language.name} (${language.nativeName})`;
  }

  openModal() {
    this.modalOpened = true;
  }

  closeModal() {
    this.modalOpened = false;
  }
}

export default About;
